<template>
	<section id="contact" class="section bg-light"
		:style="{ 'background-image': 'url(' + require('@/assets/images/bg/pattern4.png') + ')', 'background-size': 'contain', 'background-position': 'top center' }">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<p class="section-title text-center">联系我们</p>
					<div class="section-title-border"></div>
					<p class="black7 text-center font-11 padding-top-12">CONTACT US</p>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-4  margin-top-15">
					<div class="mt-2 pt-4 contactinfo">
						<!-- 弹层展示开始 -->
						<el-popover placement="top" ref="contactewmshow" title="" width="180" height="180"
							trigger="hover">
							<el-image :src="require('@/assets/images/bg/gzh.png')" fit="fit"></el-image>
						</el-popover>
						<!-- 弹层展示结束 -->
						<p class="font-16 white padding-top-8">{{ this.sys.company.Name }}</p>
						<p class="font-10 white">{{ this.sys.company.Ename }}</p>
						<p class="font-14 white padding-top-15"><i class="czlicon padding-right-8">&#xe642;</i>{{
			this.sys.company.Address }}</p>
						<p class="font-14 white"><i class="czlicon padding-right-8">&#xe77f;</i>{{ this.sys.company.Tel
							}}
						</p>
						<p class="font-14"><a :href="this.sys.company.Web" target="_blank" style="color:#fff;"><i
									class="czlicon padding-right-8">&#xe6c7;</i>{{ this.sys.company.Web }}</a></p>
						<p class="font-14"><a href="javascript:void(0);" style="color:#fff;" v-popover:contactewmshow><i
									class="czlicon padding-right-8">&#xe69b;</i>微信公众号，点击扫码关注获取更多信息</a></p>
						<p class="font-14"><a :href="`mailto:${this.sys.company.Email}`" style="color:#fff;"><i
									class="czlicon padding-right-8">&#xe63d;</i>{{ this.sys.company.Email }}</a></p>
					</div>
				</div>
				<div class="col-lg-8">
					<div class="custom-form mt-4 pt-4 myshadow4 bg-white">
						<el-form ref="contactform" :model="formdata" @submit.native.prevent="Submit()"
							class="padding-lr-20 padding-top-12 padding-bottom-30">
							<el-row :gutter="20">
								<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12">
									<div class="grid-content">
										<el-form-item>
											<el-input v-model="formdata.name" placeholder="您的姓名*"></el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12">
									<div class="grid-content">
										<el-form-item>
											<el-input v-model="formdata.phone" placeholder="手机号码*"></el-input>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-row :gutter="20">
								<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12">
									<div class="grid-content">
										<el-form-item>
											<el-input v-model="formdata.email" placeholder="电子邮件"></el-input>
										</el-form-item>
									</div>
								</el-col>
								<el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12">
									<div class="grid-content">
										<el-form-item>
											<el-input v-model="formdata.company" placeholder="单位名称"></el-input>
										</el-form-item>
									</div>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item>
										<el-input type="textarea" rows="6" placeholder="请填写留言内容*"
											v-model="formdata.content"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<div class="feedbackarea">
										<div>已提交留言？请 <span @click="openinput()">点击此处</span> 查看回复信息</div>
										<div class="text-right"><button type="submit"
												class="submitBnt btn btn-custom">提交留言</button></div>
									</div>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'Contact',
	data() {
		return {
			feedback: [],
			formdata: { name: '', phone: '', email: '', company: '', content: '' }
		};
	},
	mounted() {
		this.$nextTick(() => {
			const layui = window.layui;
			layui.use(["layer"], () => {
				var layer = layui.layer;
			});
		});
	},
	methods: {
		openinput() {
			this.$prompt('请输入留言手机号码', '查看回复', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^1[3456789]\d{9}$/,
				inputErrorMessage: '手机号码格式不正确'
			}).then(({ value }) => {
				var index = layer.load();
				this.$axios.post('web/checkfeedback/', { phone: value }).then(res => {
					layer.close(index);
					if (res.status == 'ok') {
						layer.open({
							type: 2,
							title: "留言信息",
							shade: 0.1,
							shadeClose: true,
							scrollbar: false,
							area: [this.grace.isModile() ? "90%" : "1000px", this.grace.isModile() ? "80%" : "60%"],
							content: "https://www.jiyuanxinxi.net/#/reply?phone=" + value,
						});
					} else {
						this.$Message({ message: '没有检索到您的留言信息', showClose: true, offset: 300 });
					}
				}).catch(err => { layer.close(index); this.$Message({ message: '查询发生错误请稍后重试', showClose: true, offset: 300 }); })
			}).catch(() => { });
		},
		Submit() {
			var name = this.grace.trim(this.formdata.name);
			var phone = this.grace.trim(this.formdata.phone);
			var email = this.grace.trim(this.formdata.email);
			var company = this.grace.trim(this.formdata.company);
			var content = this.grace.trim(this.formdata.content);

			if (name.length < 2) {
				this.$Message({ message: '请填写您的姓名信息', showClose: true, offset: 300 });
				return false;
			}
			if (!this.grace._isMobile(phone)) {
				this.$Message({ message: '请检查联系手机号码是否正确', showClose: true, offset: 300 });
				return false;
			}
			if (email.length > 0 && !this.grace._isEmail(email)) {
				this.$Message({ message: '请检查输入电子邮件是否正确', showClose: true, showClose: true, offset: 300 });
				return false;
			}
			if (content.length < 10) {
				this.$Message({ message: '请检查您的留言内容信息', showClose: true, offset: 300 });
				return false;
			}
			this.$axios.post('web/webfeedback', {
				name: name,
				phone: phone,
				email: email,
				company: company,
				content: content
			}).then(res => {
				if (res.status == 'ok') {
					this.formdata = { name: '', phone: '', email: '', company: '', content: '' };
					this.$Message({ message: '留言信息提交成功', showClose: true, type: 'success' });
				} else {
					this.$Message({ message: '信息提交失败请稍后重试', showClose: true, type: 'error' });
				}
			}).catch(err => {
				this.$Message({ message: '信息提交失败请稍后重试', showClose: true, type: 'error' });
			})
		}
	}
}
</script>
<style scoped>
.feedbackarea {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.feedbackarea div {
	display: flex;
	color: #aaaaaa;
	font-size: 13px;
	align-items: center;
}

.feedbackarea span {
	color: #2b71fe;
	cursor: pointer;
	padding: 0 3px;
	font-size: 13px;
}
</style>
